import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SideMenu.css';
import { API_URL } from './config'; 
import CSRFToken from './CSRFToken';
import { getCookie } from './utils';
import FeedbackForm from './FeedbackForm';
import { useUser } from './UserContext';
import WelcomePopup from './WelcomePopup';
import Isotipo from './images/Isotipo_TrueNorthAI.png';
import Logotipo from './images/Logotipo_TrueNorthAI.png';

const FEATURES = ['AI Career Coach', 'Profile', 'AI Mentors', 'Cover Letter Builder', 'Resume Builder', 'Career Track', 'Interview Prep'];

const SideMenu = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('token');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { currentUser } = useUser();
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [showWelcomePopup, setShowWelcomePopup] = useState(false);

    useEffect(() => {
        // Only show the feedback form if the user hasn't given feedback yet
        if (currentUser && localStorage.getItem(`feedback_given_${currentUser.username}`) !== 'true') {
            let loginTime = localStorage.getItem(`loginTime_${currentUser.username}`);
            if (!loginTime) {
                // Record the login time if it's not already recorded
                loginTime = Date.now();
                localStorage.setItem(`loginTime_${currentUser.username}`, loginTime.toString());
            }
            const previousTimeSpent = parseInt(localStorage.getItem(`timeSpent_${currentUser.username}`)) || 0;
            
            const currentTime = Date.now();

            const timePassedSinceLogin = currentTime - parseInt(loginTime);

            const timeRemaining = 20 * 60 * 1000 - previousTimeSpent - timePassedSinceLogin;
            const timeoutId = setTimeout(() => {
                setShowFeedbackForm(true);
                localStorage.setItem(`feedback_given_${currentUser.username}`, 'true');
            }, timeRemaining);
        
            return () => {
                clearTimeout(timeoutId);
                const timeSpent = Date.now() - parseInt(loginTime);
                localStorage.setItem(`timeSpent_${currentUser.username}`, (previousTimeSpent + timeSpent).toString());
                localStorage.removeItem(`loginTime_${currentUser.username}`);
            };
        }
    }, [currentUser]);

    useEffect(() => {
        // fetch user data from your API
        fetch(`${API_URL}/api/welcome/`, { 
            method: 'GET', 
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(data => setShowWelcomePopup(!data.welcomePopupShown))
        .catch(error => console.error('Error:', error));
    }, [token]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSubmitFeedback = async (feedbackData) => {
        setShowFeedbackForm(false);
        try {
            const response = await fetch(`${API_URL}/api/feedback/`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: currentUser.username, ...feedbackData })
            });
        
            if (response.ok) {
            // If the feedback was successfully submitted, set a flag in the local storage
            localStorage.setItem(`feedback_given_${currentUser.username}`, 'true');
            } else {
            console.error(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.log('Error submitting feedback', error);
        }
    };

    const handleCloseFeedback = () => {
        setShowFeedbackForm(false);
    };

    const menuItems = [
        { path: '/app/chat', label: 'AI Career Coach', icon: 'fa-solid fa-comment' },
        { path: '/app/profile', label: 'Profile', icon: 'fa-solid fa-address-card' },
        { path: '/app/mentors', label: 'AI Mentors', icon: 'fa-solid fa-rocket' },
        { path: '/app/cover-letter', label: 'Cover Letter Builder', icon: 'fa-solid fa-file' },
        { path: '/app/resume', label: 'Resume Builder', icon: 'fa-solid fa-file' },
        { path: '/app/career-track', label: 'Career Track', icon: 'fa-solid fa-chart-line' },
        { path: '/app/interview-prep', label: 'Interview Prep', icon: 'fa-solid fa-dumbbell' },
    ];

    const handleLogout = () => {
        const csrftoken = getCookie('csrftoken');
        // Call the backend API to log out
        fetch(`${API_URL}/api/logout/`, { 
            method: 'POST', 
            credentials: 'include', // This is important to include the session cookie
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                'X-CSRFToken': csrftoken,
            },
        })
        .then((response) => {
            if (response.ok) {
                localStorage.removeItem('token');
                // Redirect user to the login page after logout
                navigate('/');
            } else {
                console.error('Failed to log out');
            }
        })
        .catch((error) => console.error('Error:', error));
    };

    return (
        <div>
            {isMobile} 
            <div className={isMobile ? "side-menu" : "side-menu desktop-side-menu"}>
                {isMobile ? children : (
                    <div>
                        <div className="menu-title" style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt="logo" src={Isotipo} style={{ width:'60px', height:'auto' }} />
                            <img alt="logotype" src={Logotipo} style={{ width:'140px', height:'auto' }} />
                        </div>
                        <CSRFToken />
                        <hr className="title-separator" />
                        {menuItems.map((item) => (
                            <div
                                key={item.path}
                                className={`menu-item${location.pathname === item.path ? ' active' : ''}`}
                                onClick={() => navigate(item.path)}
                            >
                                <i className={`fas ${item.icon}`} style={{position: 'absolute', left: '10px'}}></i>
                                {item.label}
                            </div>
                        ))}
                        <hr className="separator" />
                        <button className="menu-item feedback-button" onClick={() => setShowFeedbackForm(true)}>
                            <i className="fa-solid fa-envelope-open" style={{position: 'absolute', left: '10px'}}></i>
                            <div className="button-text">
                                Send Feedback
                            </div>
                        </button>
                        <button className="menu-item logout-button" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt" style={{position: 'absolute', left: '10px'}}></i>
                            <div className="button-text">
                                Log Out
                            </div>
                        </button>
                    </div>
                )}
            </div>
            {showFeedbackForm && (
                <FeedbackForm features={FEATURES} onSubmit={handleSubmitFeedback} onClose={handleCloseFeedback} />
            )}
            {showWelcomePopup && (
                <WelcomePopup setShowWelcomePopup={setShowWelcomePopup} token={token} />
            )}
        </div>
    );
};

export default SideMenu;



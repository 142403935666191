import React, { useState, useRef, useEffect, useCallback } from 'react';
import './ChatView.css';
import { useUser } from './UserContext';
import chat_avatar from './profile_pics/coach7.png';
import { API_URL } from './config'; 
import { Button, TextField, Avatar } from '@mui/material';
import TypingIndicator from './TypingIndicator';
import SendIcon from '@mui/icons-material/Send';

const ChatView = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const messageContainerRef = useRef(null);
  const [showScrollToBottomArrow, setShowScrollToBottomArrow] = useState(false);
  const { currentUser } = useUser();  
  const token = localStorage.getItem('token');
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [profile_picture, setProfilePicture] = useState('');

  // Function to handle scrolling to the bottom
  const handleScroll = useCallback(() => {
    const messageContainer = messageContainerRef.current;
    const isScrolledToBottom = messageContainer.scrollHeight - messageContainer.scrollTop <= messageContainer.clientHeight + 1;
    setShowScrollToBottomArrow(!isScrolledToBottom);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent adding a new line
      sendMessage(event);
    }
  };

  const chatContainerRef = useRef(null); // Add this ref to access chat-container

  useEffect(() => {
    const setChatContainerHeight = () => {
      // Get the viewport height
      const viewportHeight = window.innerHeight;

      // Get the height of the navbar
      // You can also manually set this if it's fixed, e.g. 60px
      const navbar = document.querySelector('.navbar'); // adjust the selector for your navbar
      const navbarHeight = navbar ? navbar.offsetHeight : 0; // fallback to 60px if navbar is not found
      const appbar = document.querySelector('.mobile-app-bar'); // adjust the selector for your appbar
      const appbarHeight = appbar ? appbar.offsetHeight : 0; // fallback to 0px if appbar is not found

      // Calculate the height chat-container should have
      const chatContainerHeight = viewportHeight - navbarHeight - appbarHeight;

      // Apply this height to chat-container
      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = `${chatContainerHeight}px`;
      }
    };

    // Call the function to set the height initially
    setChatContainerHeight();

    // Update the height when the window is resized
    window.addEventListener('resize', setChatContainerHeight);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', setChatContainerHeight);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [messages, handleScroll]);

  // Fetch conversation history on component mount
  useEffect(() => {
    const fetchConversationHistory = async () => {
      try {
        const response = await fetch(`${API_URL}/api/get_conversation_history/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify({ username: currentUser.username }),
        });

        if (!response.ok) {
          throw new Error(response.data.message);
        }

        const data = await response.json();
        if(data.messages){
            setMessages(data.messages);
        } else {
            setMessages([]); // empty array if no messages
        }

      } catch (error) {
        console.error('Failed to fetch conversation history', error);
      }
    };

    if (currentUser && currentUser.username){
        fetchConversationHistory();
        fetchUserBasicInfo(currentUser.username);
    }
  }, [currentUser, token]);

  const fetchUserBasicInfo = async (username) => {
    const token = localStorage.getItem('token');
    try {
        // Send the user's message to the backend API for processing
        const response = await fetch(`${API_URL}/api/get_user_basic/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({ username: username }),
        });

        if (response.ok) {
            const data = await response.json();
            setProfilePicture(data.profile_picture);
        } else {
            console.error('Failed to fetch user info');
        }

    } catch (error) {
        console.error('An error occurred while fetching user info:', error);
        // Handle error case if necessary
    }
  };

  // Function to handle sending a message
  const sendMessage = async (event) => {
    event.preventDefault();

    if (inputText.trim() !== '') {
      // Add the user's message to the conversation
      setMessages((prevMessages) => [...prevMessages, 
        { content: inputText, role: 'user' }, 
        { content: '', role: 'system', isLoading: true }]
      );
      
      const message = inputText;
      // Clear the input field
      setInputText('');
      try {
        // Send the user's message to the backend API for processing
        const response = await fetch(`${API_URL}/api/chatgpt/`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
          },
          body: JSON.stringify({ user: currentUser.username, message: message }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch response from ChatGPT API');
        }

        // Get the response from the backend API
        const data = await response.json();
        
        // Set isLoading to false as we're about to start typing
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1].isLoading = false;
          return newMessages;
        });

        // Simulate typing effect for chatbot's response
        const fullText = data.response;
        let index = 0;
        const typingEffect = () => {
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[newMessages.length - 1].content = fullText.slice(0, index);
            return newMessages;
          });
          index++;
          if (index <= fullText.length) {
            setTimeout(typingEffect, 10); // 50ms between each character
          }
        };
        typingEffect();
      } catch (error) {
        console.error(error);
        setInputError(true);
        setErrorMessage("Failed to send message. Please try again.");
      }
    }
  };

  // Function to handle user input
  const handleInputChange = (event) => {
    setInputText(event.target.value);
    if (inputError) {
      setInputError(false);
      setErrorMessage('');
    }
  };

  // Listener to handle scrolling to the bottom when the user not at bottom
  useEffect(() => {
    const messageContainer = messageContainerRef.current;
    messageContainer.addEventListener('scroll', handleScroll);
    return () => {
      messageContainer.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Effect to scroll to the bottom when new messages are added
  useEffect(() => {
    const messageContainer = messageContainerRef.current;
    messageContainer.scrollTop = messageContainer.scrollHeight;
  }, [messages]);

  // Function to handle scrolling to the bottom when the arrow is clicked
  const handleScrollToBottom = () => {
    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
  }; 

  return (
    <div className="chat-container"  ref={chatContainerRef}>
      <div className="message-wrapper">
        <div className="message-container" ref={messageContainerRef}>
          {messages && messages.map((message, index) => (
            <div key={index} className={`message ${message.role === 'user' ? 'user' : 'system'}`} >
              <div className="message-content">
                  <div className={`message-icon ${message.role === 'user' ? 'user-icon' : 'system-icon'}`}>
                      {message.role === 'user' ? (
                        <Avatar src={profile_picture} alt="User" />
                      ) : (
                        <Avatar src={chat_avatar} alt="System" />
                      )}
                  </div>
                  <div className="message-text">                    
                      {message.isLoading ? (
                        <TypingIndicator />
                      ) : (
                      <pre>{message.content}</pre>
                      )}
                  </div>
              </div>
            </div>
          ))}
        </div>
        {showScrollToBottomArrow && (
          <div className="scroll-to-bottom-arrow" onClick={handleScrollToBottom}>
            <i className="fas fa-arrow-down"></i>
          </div>
        )}
      </div>
      <form className="input-form" onSubmit={sendMessage}>
        <TextField
          multiline
          maxRows={8}
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Send a message"
          className='cool-scrollbar'
          error={inputError}
          helperText={errorMessage}
          sx={{
            flex: 1,
            backgroundColor: 'white',
            borderRadius: 1,
            mr: 1,
            border: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent !important',
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disableElevation
          sx={{
            backgroundColor: 'rgb(44, 133, 109)',
            borderRadius: '10px',
            p: '10px 6px',
            mr: 1,
            minWidth: '36px',
            height: '36px',
            '&:hover': {
              backgroundColor: 'rgb(32, 92, 76)',
            },
          }}
        >
          <SendIcon />
        </Button>
      </form>
      <div className="disclaimer">
          True North AI may produce inaccurate information.
      </div>
    </div>
  );
};

export default ChatView;


import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Rating from '@mui/material/Rating';
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/system/Box";

const FeedbackForm = ({ features, onSubmit, onClose }) => {
  // Initialize state with features
  const [formData, setFormData] = useState(
    features.map(feature => ({ name: feature, rating: 3, order: features.indexOf(feature) }))
  );

  const [suggestions, setSuggestions] = useState("");
  const [extraFeatures, setExtraFeatures] = useState("");

  // Function to handle when an item is dragged and dropped
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(formData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormData(items.map((item, index) => ({ ...item, order: index })));
  };

  const handleRatingChange = (name, rating) => {
    setFormData(formData.map(item => item.name === name ? { ...item, rating: rating } : item));
  };

  const handleSubmit = () => {
    onSubmit({ features: formData, suggestions, extraFeatures });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <DialogTitle>
        Feedback
        <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="features">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {formData.sort((a, b) => a.order - b.order).map(({ name, rating, order }, index) => (
                  <Draggable key={name} draggableId={name} index={index}>
                    {(provided) => (
                      <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, p: 2, bgcolor: 'background.default', borderRadius: 2 }}>
                        <div>{name}</div>
                        <Rating
                          name={name}
                          value={rating}
                          precision={0.5}
                          onChange={(event, newValue) => {
                            handleRatingChange(name, newValue);
                          }}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <TextField
          margin="dense"
          id="suggestions"
          label="Suggestions"
          type="text"
          fullWidth
          variant="outlined"
          value={suggestions}
          onChange={e => setSuggestions(e.target.value)}
          multiline
        />

        <TextField
          margin="dense"
          id="extraFeatures"
          label="Extra Features"
          type="text"
          fullWidth
          variant="outlined"
          value={extraFeatures}
          onChange={e => setExtraFeatures(e.target.value)}
          multiline
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackForm;

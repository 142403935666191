import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { API_URL } from './config';

const WelcomePopup = ({ setShowWelcomePopup, token }) => {

    const handleClose = async () => {
        // Mark welcome message as seen for the user on the backend
        await fetch(`${API_URL}/api/mark_welcome_seen/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        });

        // Close the popup
        setShowWelcomePopup(false);
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>Welcome to True North AI!</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    We're excited to have you onboard the ultimate career matching platform. As you embark on this journey, be prepared to find your ideal career paths and land your dream job. Your first step starts here, with the AI career coach. 
                </DialogContentText>
                <br></br>
                <DialogContentText>
                    Feel free to chat with the AI career coach just like you would with a human coach. Discuss about different industries, uncover unique opportunities, and most importantly, discover the career path that perfectly aligns with your personality, interests, skills, and experience. To start the conversation, just say "Hi" to the AI career coach. Your future awaits you!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Start now
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WelcomePopup;

import React, { useState, useEffect, useRef } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Avatar,
    Button,
    TextField,
    CircularProgress,
    IconButton,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { BsRobot } from 'react-icons/bs';
import { API_URL } from "./config";
import { useUser } from './UserContext';
import PublishIcon from '@mui/icons-material/Publish';

const UserProfile = () => {
    const { currentUser } = useUser();
    const [user, setUser] = useState(null);
    const [editingField, setEditingField] = useState("");
    const [loading, setLoading] = useState(true);
    const chatContainerRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [fields, setFields] = useState({
        workExperience: { value: '', aiUpdating: false },
        // ... other fields
      });
    const fileInputRef = useRef(null);

    useEffect(() => {
        const setChatContainerHeight = () => {
          const viewportHeight = window.innerHeight;
          const navbar = document.querySelector('.navbar'); // adjust the selector for your navbar
          const navbarHeight = navbar ? navbar.offsetHeight : 0; // fallback to 60px if navbar is not found
          const appbar = document.querySelector('.mobile-app-bar'); // adjust the selector for your appbar
          const appbarHeight = appbar ? appbar.offsetHeight : 0; // fallback to 0px if appbar is not found
        
          // Calculate the height chat-container should have
          const chatContainerHeight = viewportHeight - navbarHeight - appbarHeight;
          if (chatContainerRef.current) {
            chatContainerRef.current.style.height = `${chatContainerHeight}px`;
          }
        };
        setChatContainerHeight();
        window.addEventListener('resize', setChatContainerHeight);
        return () => {
          window.removeEventListener('resize', setChatContainerHeight);
        };
      }, []);

    useEffect(() => {
        const fetchUserData = async (username) => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${API_URL}/api/get_user_basic/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                    body: JSON.stringify({ username: username })
                });
                const data = await response.json();
                setUser(data);
                setFields(prevFields => ({
                    ...prevFields,
                    workExperience: {
                        ...prevFields.workExperience,
                        value: data.workExperience || '', 
                    },
                    education: {
                        ...prevFields.education,
                        value: data.education || '', 
                    },
                    certifications: {
                        ...prevFields.certifications,
                        value: data.certifications || '',
                    },
                    skills: {
                        ...prevFields.certifications,
                        value: data.skills || '', 
                    },
                    interests: {
                        ...prevFields.certifications,
                        value: data.interests || '', 
                    },
                    languages: {
                        ...prevFields.certifications,
                        value: data.languages || '', 
                    }
                }));
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };

        if (currentUser && currentUser.username){       
            fetchUserData(currentUser.username);
        }
    }, [currentUser]);

    const handleSave = async (field) => {
        try {
            await fetch(`${API_URL}/api/update_user/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: currentUser.username, field: field, value: fields[field].value })
            });
            setUser({ ...user, [field]: fields[field].value }); 
        } catch (error) {
            console.error("Error updating user data:", error);
        }
        setEditingField("");
    };

    if (loading) {
        return <CircularProgress />;
    }

    const handleAIUpdate = async (field) => {
        const token = localStorage.getItem('token');
        setFields(prevState => ({ ...prevState, [field]: { ...prevState[field], aiUpdating: true } }));
        try {
            const response = await fetch(`${API_URL}/api/ai_update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: currentUser.username, field: field })
            });
            const data = await response.json();
            // Simulate typing effect
            const fullText = data.updated_text;
            let index = 0;
            const typingEffect = () => {
                setFields(prevState => ({
                    ...prevState,
                    [field]: {
                        value: fullText.slice(0, index),
                        aiUpdating: index < fullText.length - 1 ? true : false
                    }
                }));
                index++;
                if (index <= fullText.length) {
                    setTimeout(typingEffect, 10); // 50ms between each character
                }
            };
            typingEffect();
            setEditingField(field);
        } catch (error) {
            console.error("Error updating user data with AI:", error);
            setFields(prevState => ({ ...prevState, [field]: { ...prevState[field], aiUpdating: false } }));
        }
    };

    const handleResumeUploadClick = () => {
        // Programmatically click the hidden file input element
        // when the button is clicked
        fileInputRef.current.click();
    };

    const handleResumeChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Process the selected file
            uploadResume(file);
        }
    };

    const uploadResume = async (file) => {
        const token = localStorage.getItem('token');
        // Create FormData to hold the file
        const formData = new FormData();
        formData.append('resume', file);

        // Upload the file to your server
        try {
            const response = await fetch(`${API_URL}/api/upload_resume/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`
                },
                body: formData
            });
            // Handle the response as needed
            const data = await response.json();
            setUser(prevUser => ({ ...prevUser, resume: data.resume }));
            console.log("Resume uploaded successfully", data);
        } catch (error) {
            console.error("Error uploading resume:", error);
        }
    };

    const handleFileInput = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    
    const handleUpload = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', selectedFile);
        
        try {
            const response = await fetch(`${API_URL}/api/upload_profile_picture/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Authorization': `Token ${token}`
                },
                body: formData,
            });
            const data = await response.json();
            // Refresh the profile picture after a successful upload.
            setUser(prevUser => ({ ...prevUser, profile_picture: data.profile_picture }));
        } catch (error) {
            console.error("Error uploading picture:", error);
        }
        handleClose();
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Box
            className="profile-container"
            sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F0F0F0',
                    borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#ccc',
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: '#bbb'
                    }
                }
            }}
            ref={chatContainerRef}
        >
            <Grid container spacing={4} sx={{ height: '100%', p: 3 }}>
                {/* Left Side */}
                <Grid item xs={12} md={4}>
                    <Card sx={{ p: 3, display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
                        <div style={{ position: 'relative' }}>
                            <Avatar alt={user.name} src={user.profile_picture} sx={{ width: 128, height: 128 }} />
                            <IconButton
                                aria-label="edit picture"
                                size="small"
                                style={{ position: 'absolute', bottom: -5, right: -10 }}
                                onClick={handleOpen}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </div>
                        <Typography variant="h4" sx={{ mt: 2 }}>
                            {user.name} {user.last_name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            {user.email}
                        </Typography>
                    </Card>
                    <Card sx={{ p: 0, display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                            <Typography variant="h5" sx={{ display: "inline" }}>
                                Resume (PDF)
                            </Typography>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleResumeChange}
                            />
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                color="secondary"
                                onClick={handleResumeUploadClick}
                            >
                                <PublishIcon fontSize="inherit" />
                            </IconButton>
                            <Typography variant="body1">
                                {user.resume ? user.resume : "No file uploaded"}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ p: 0, mt: 2 }}>
                        <CardHeader 
                            title={<Typography variant="body2" color="primary">Click on this icon at each section to generate assessment with AI.</Typography>}
                            avatar={<BsRobot color="rgb(44, 133, 109)" />}
                        />
                    </Card>
                </Grid>
                {/* Right Side */}
                <Grid item xs={12} md={8}>
                    {/* Work Experience */}
                    <Card sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Work Experience
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                onClick={() => setEditingField("workExperience")}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                sx={{ ml: 1 }}
                                color="primary"
                                size="small"
                                onClick={() => handleAIUpdate("workExperience")}
                            >
                                <BsRobot size={20} />
                            </IconButton>
                            {fields.workExperience.aiUpdating && <CircularProgress size={20} />}
                        </Typography>
                        {editingField === "workExperience" ? (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <TextField
                                        value={fields.workExperience.value}
                                        multiline
                                        fullWidth
                                        onChange={(e) =>
                                            setFields(prevState => ({
                                              ...prevState,
                                              workExperience: { ...prevState.workExperience, value: e.target.value },
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => handleSave("workExperience")}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
                                {fields.workExperience.value}
                            </Typography>
                        )}
                    </Card>
                    {/* Education */}
                    <Card sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Education
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                onClick={() => setEditingField("education")}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                sx={{ ml: 1 }}
                                color="primary"
                                size="small"
                                onClick={() => handleAIUpdate("education")}
                            >
                                <BsRobot size={20} />
                            </IconButton>
                            {fields.education.aiUpdating && <CircularProgress size={20} />}
                        </Typography>
                        {editingField === "education" ? (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <TextField
                                        value={fields.education.value}
                                        multiline
                                        fullWidth
                                        onChange={(e) =>
                                            setFields(prevState => ({
                                              ...prevState,
                                              education: { ...prevState.education, value: e.target.value },
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => handleSave("education")}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
                                {fields.education.value}
                            </Typography>
                        )}
                    </Card>

                    {/* Certifications */}
                    <Card sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Certifications
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                onClick={() => setEditingField("certifications")}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                sx={{ ml: 1 }}
                                color="primary"
                                size="small"
                                onClick={() => handleAIUpdate("certifications")}
                            >
                                <BsRobot size={20} />
                            </IconButton>
                            {fields.certifications.aiUpdating && <CircularProgress size={20} />}
                        </Typography>
                        {editingField === "certifications" ? (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <TextField
                                        value={fields.certifications.value}
                                        multiline
                                        fullWidth
                                        onChange={(e) =>
                                            setFields(prevState => ({
                                              ...prevState,
                                              certifications: { ...prevState.certifications, value: e.target.value },
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => handleSave("certifications")}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
                                {fields.certifications.value}
                            </Typography>
                        )}
                    </Card>

                    {/* Skills */}
                    <Card sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Skills
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                onClick={() => setEditingField("skills")}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                sx={{ ml: 1 }}
                                color="primary"
                                size="small"
                                onClick={() => handleAIUpdate("skills")}
                            >
                                <BsRobot size={20} />
                            </IconButton>
                            {fields.skills.aiUpdating && <CircularProgress size={20} />}
                        </Typography>
                        {editingField === "skills" ? (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <TextField
                                        value={fields.skills.value}
                                        multiline
                                        fullWidth
                                        onChange={(e) =>
                                            setFields(prevState => ({
                                              ...prevState,
                                              skills: { ...prevState.skills, value: e.target.value },
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => handleSave("skills")}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
                                {fields.skills.value}
                            </Typography>
                        )}
                    </Card>

                    {/* Interests */}
                    <Card sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Interests
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                onClick={() => setEditingField("interests")}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                sx={{ ml: 1 }}
                                color="primary"
                                size="small"
                                onClick={() => handleAIUpdate("interests")}
                            >
                                <BsRobot size={20} />
                            </IconButton>
                            {fields.interests.aiUpdating && <CircularProgress size={20} />}
                        </Typography>
                        {editingField === "interests" ? (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <TextField
                                        value={fields.interests.value}
                                        multiline
                                        fullWidth
                                        onChange={(e) =>
                                            setFields(prevState => ({
                                              ...prevState,
                                              interests: { ...prevState.interests, value: e.target.value },
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => handleSave("interests")}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography style={{ whiteSpace: "pre-wrap" }} variant="body1">
                                {fields.interests.value}
                            </Typography>
                        )}
                    </Card>

                    {/* Languages */}
                    <Card sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h5" sx={{ mb: 2 }}>
                            Languages
                            <IconButton
                                sx={{ ml: 1 }}
                                size="small"
                                onClick={() => setEditingField("languages")}
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Typography>
                        {editingField === "languages" ? (
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <TextField
                                        value={fields.languages.value}
                                        multiline
                                        fullWidth
                                        onChange={(e) =>
                                            setFields(prevState => ({
                                              ...prevState,
                                              languages: { ...prevState.languages, value: e.target.value },
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => handleSave("languages")}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            user.languages
                        )}
                    </Card>

                    {/* Other sections can follow a similar pattern */}
                </Grid>
            </Grid>  
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload Profile Picture</DialogTitle>
                <DialogContent>
                    <input type="file" onChange={handleFileInput} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleUpload}>Upload</Button>
                </DialogActions>
            </Dialog>    
        </Box>                                                                       
    );
};

export default UserProfile;

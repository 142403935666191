import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './HeroSection.css';
import Imagotipo from './images/Imagotipo_TrueNorthAI.png';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const HeroSection = () => {
    return (
        <Box id="hero-section" className="hero-section">
            <Grid container>
                <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }} container direction="column" justifyContent="center" alignItems="center">
                    <Typography variant="h1">Introducing True North AI</Typography>
                    <Typography className="subtitle" variant="h6">A revolutionary AI-powered platform that matches you with your ideal career path and coaches you to land your dream job.</Typography>
                    <Link to="signup" className="try-evolve-btn">
                        Try True North AI
                        <ArrowOutwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Link>
                </Grid>
                <Grid item xs={12} sm={5} order={{ xs: 1, sm: 2 }} container direction="column" justifyContent="center" alignItems="center">
                    <img src={Imagotipo} alt="Logo" style={{ width: '80%', height: 'auto' }} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeroSection;
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import './ProductSection.css';
import Logotipo from './images/Logotipo_TrueNorthAI.png'
import productImage from './images/product.jpg'

const ProductSection = () => {
    return (
        <Box id="product-section" className="product-section" component={Paper} elevation={3}>
            <Container>
                <Grid container pt={5} alignItems="center">
                    {/* Product Image */}                    
                    <Grid item xs={12} sm={7} className="product-image" mb={3}>
                        <div className="outter" style={{
                            position: 'relative',
                            width: '100%',
                            height: '400px', // change this to your requirement
                            overflow: 'hidden',
                        }}>
                            <img 
                                src={productImage} 
                                alt="Product" 
                                style={{
                                    width: '100%', 
                                    height: '100%', 
                                    position: 'absolute', 
                                    objectFit: 'cover', 
                                    zIndex: 1 
                                }} 
                            />
                            <div className="transparency" style={{
                                content: '""', 
                                position: 'absolute', 
                                top: 0, 
                                left: '50%', 
                                width: '50%', 
                                height: '100%', 
                                background: 'linear-gradient(to right, transparent, #232942)', 
                                zIndex: 2 
                            }}></div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5} className="product-text">
                            <Grid item xs={12}>
                                <img className="item-content first" src={Logotipo} alt="Logo" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="item-content first" variant="h5" gutterBottom>Your Ultimate Career Path Navigator </Typography>
                            </Grid>
                            <Grid item xs={12} mx={2}>
                                <Typography className="item-content first" variant="body1" gutterBottom>Embarking on a career journey is like sailing uncharted seas and True North AI, the cutting-edge AI career matching platform, is here to be your compass. With a deep understanding of your personality, interests, skills, and experience, it will guide you to your dream job.</Typography>
                            </Grid>
                    </Grid>
                </Grid>
                <Grid container pb={5}>
                    {/* Product Text */}
                    <Grid item xs={12} sm={6} className="product-text" px={2}>
                            <Typography variant="h6">Advanced AI Career Matching</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Personalized User Profiling:</strong> True North AI leverages cutting-edge AI to analyze your personality, interests, skills, and experiences to build a comprehensive user profile.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Ideal Career Path Identification:</strong> The platform presents you with tailored career options that align with your profile and ambitions.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Skill Gap Analysis:</strong> True North AI identifies areas where you can develop or enhance your skills to bolster your profile for your target career.<br />
                            </Typography>
                            <Divider variant="middle" sx={{ my: 2 }} color='#232942' />
                            <Typography variant="h6">24/7 Personalized AI Career Coach</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Expertise at Your Fingertips:</strong> Powered by cutting-edge Large Language Models that harness the collective knowledge of the internet, our AI career coaches are available 20 hours a day, 7 days a week.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Trained by the Masters:</strong> Our AI career coaches are trained by top career professionals to ensure the advice you receive is astute and actionable.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Customized Guidance:</strong> Receive bespoke career advice and recommendations based on your unique profile.<br />
                            </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} className="product-text" px={2}>
                            <Typography variant="h6">AI-Powered Resume & Cover Letter Builders</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Resume Tailoring:</strong> Enhance your resume with the AI-driven resume builder that tailors your experience and skills to the job you’re applying for.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Standout Cover Letters:</strong> Create compelling cover letters with our builder, which is primed to give you the edge in an AI-driven recruiting process.<br />
                            </Typography>
                            <Divider variant="middle" sx={{ my: 2 }} color='#232942' />
                            <Typography variant="h6">AI Mentors Across Industries</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Diverse Range of AI Mentors</strong>: Chat with AI characters that simulate professionals from various industries and experience levels.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Insider Knowledge:</strong> Get insights into the day-to-day realities of different jobs and glean invaluable advice from your AI mentors.<br />
                            </Typography>
                            <Divider variant="middle" sx={{ my: 2 }} color='#232942' />
                            <Typography variant="h6">Interview Preparation</Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Mock Interviews:</strong> True North AI integrates personalized mock interviews tailored to specific roles and companies, enhancing your preparation for any job application.<br />
                                <strong style={{ color:'#7696CE', fontStyle:'italic' }}>Resources:</strong> Comprehensive AI-driven resources tailored to various roles and companies, offering personalized insights and skill development tools to empower job seekers in navigating the recruitment process effectively.<br />
                            </Typography>
                    </Grid>
                    {/* Final section */}
                    <Grid container spacing={2} px={2}>
                        <Grid item xs={12} sm={12} className="product-text">
                            <Typography variant="body1" align="center" gutterBottom>
                                <br></br>
                                Unleash your potential and set sail with True North AI and let us guide you in your career exploration, skill-building, and job attainment. With True North AI, your dream job is no longer just a star in the sky – it’s your destination.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button variant="outlined" color="white" href="signup">
                                Get Started Now
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ProductSection;

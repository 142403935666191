import React, { useState, useEffect, useRef } from "react";
import {
    Container, Typography, Grid, TextField, Button, Box, Divider, Card, CardContent, ListItem
} from "@mui/material";
//import InterviewBotImage from './images/interview-bot.jpg'; // Replace with the path to your image
import { useNavigate } from "react-router-dom";
import { CircularProgress, IconButton } from "@mui/material";
import { API_URL } from './config'; 
import { useUser } from './UserContext';
import { BsRobot } from 'react-icons/bs';

const InterviewPrep = () => {
    const [role, setRole] = useState("");
    const [searchRole, setSearchRole] = useState('');
    const navigate = useNavigate();
    const [resources, setResources] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [idealResources, setIdealResources] = useState('');
    const { currentUser } = useUser();
    const chatContainerRef = useRef(null);
    const [pastInterviews, setPastInterviews] = useState([]);

    const handleMockInterview = (e) => {
        e.preventDefault();
        navigate('/app/interview-chat', { state: { role: role } }); 
    };

    const handleContinueInterview = (role, id) => {
        // Navigate to interview chat with the conversationId to resume the interview
        navigate('/app/interview-chat', { state: { role: role, id: id } });
    };

    useEffect(() => {
        if (currentUser && currentUser.username){
            fetchUserInfo(currentUser.username);
            fetchInterviews(currentUser.username);
        }
    }, [currentUser]);

    useEffect(() => {
        const setChatContainerHeight = () => {
          const viewportHeight = window.innerHeight;
          const navbar = document.querySelector('.navbar'); // adjust the selector for your navbar
          const navbarHeight = navbar ? navbar.offsetHeight : 0; // fallback to 60px if navbar is not found
          const appbar = document.querySelector('.mobile-app-bar'); // adjust the selector for your appbar
          const appbarHeight = appbar ? appbar.offsetHeight : 0; // fallback to 0px if appbar is not found
        
          // Calculate the height chat-container should have
          const chatContainerHeight = viewportHeight - navbarHeight - appbarHeight;
          if (chatContainerRef.current) {
            chatContainerRef.current.style.height = `${chatContainerHeight}px`;
          }
        };
        setChatContainerHeight();
        window.addEventListener('resize', setChatContainerHeight);
        return () => {
          window.removeEventListener('resize', setChatContainerHeight);
        };
      }, []);

    const fetchInterviews = async (username) => {
        const token = localStorage.getItem('token');
        try {
            // Send the user's message to the backend API for processing
            const response = await fetch(`${API_URL}/api/get_interviews/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: username }),
            });

            if (response.ok) {
                const data = await response.json();
                setPastInterviews(data);
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
            // Handle error case if necessary
        }
    };

    const fetchUserInfo = async (username) => {
        const token = localStorage.getItem('token');
        try {
            // Send the user's message to the backend API for processing
            const response = await fetch(`${API_URL}/api/get_assessment/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: username }),
            });

            if (response.ok) {
                const data = await response.json();
                setIdealResources(data.ideal_paths_resources);
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
            // Handle error case if necessary
        }
    };

    const fetchIdealResources = async () => {
        setLoading2(true);
        const token = localStorage.getItem('token');
        try {
            setIdealResources('');
            const response = await fetch(`${API_URL}/api/get_ideal_resources/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: currentUser.username }),
            });

            if (response.ok) {
                const data = await response.json();
                const fullText = data.response;
                let index = 0;
                const typingEffect = () => {
                    setIdealResources(fullText.slice(0, index));
                    index++;
                    if (index <= fullText.length) {
                        setTimeout(typingEffect, 1); 
                    }
                };
                typingEffect();
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
        }
        setLoading2(false);
    };

    const handleResourceSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = localStorage.getItem('token');
        try {           
            setResources('');
            // Send the user's message to the backend API for processing
            const response = await fetch(`${API_URL}/api/get_resources/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ role: searchRole }),
            });

            if (response.ok) {
                const data = await response.json();

                // Simulate typing effect
                const fullText = data.response;
                let index = 0;
                const typingEffect = () => {
                    setResources(fullText.slice(0, index));
                    index++;
                    if (index <= fullText.length) {
                        setTimeout(typingEffect, 1); 
                    }
                };
                typingEffect();
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
            setResources('');
        }
        setLoading(false);
    };

    return (
        <Container maxWidth="lg"
            sx={{
                py: 5,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F0F0F0',
                    borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#ccc',
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: '#bbb'
                    }
                }
            }}
            ref={chatContainerRef}
        >
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Interview Preparation
            </Typography>
            <Divider sx={{ my: 4 }} />
            <Grid container spacing={4}>

                {/* Mock Interview Section */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        {/* <CardMedia
                            component="img"
                            image={InterviewBotImage}
                            alt="Interview bot"
                            height="180"
                        /> */}
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h5" component="div">
                                Mock Interview
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Practice interviews with our AI chatbot tailored to the role you are applying for.
                            </Typography>
                            <Box component="form" onSubmit={handleMockInterview} sx={{ mt: 2 }}>
                                <TextField
                                    label="Role and Company"
                                    fullWidth
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    variant="outlined"
                                    sx={{ mb: 2 }}
                                />
                                <Button variant="contained" color="primary" type="submit">
                                    Start Practice
                                </Button>
                            </Box>
                            <Typography variant="h6" sx={{ mt: 4 }}>Past mock interviews:</Typography>
                            <Grid container spacing={2}>
                                {pastInterviews && pastInterviews.map((interview, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={9}>
                                            <ListItem variant="body2">{interview.role}</ListItem>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button color="primary" onClick={() => handleContinueInterview(interview.role, interview.id)}>
                                                Continue
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Resources Section */}
                <Grid item xs={12} md={6}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h5" component="div">
                                Resources
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Discover resources and explore opportunities in any field.
                            </Typography>
                            <Box component="form" onSubmit={handleResourceSearch} sx={{ mt: 2, mb: 3 }}>
                                <TextField
                                    label="Search by Role and Company"
                                    fullWidth
                                    variant="outlined"
                                    value={searchRole}
                                    onChange={(e) => setSearchRole(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <Button variant="contained" color="primary" type="submit">
                                    Search
                                </Button>
                            </Box>
                            {resources ? (
                                <Typography variant="body2" color="text.primary" sx={{ whiteSpace: 'pre-wrap' }}>
                                    {resources}
                                </Typography>
                            ) : loading ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            ) : null}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Ideal Resources Section */}
                <Grid item xs={12} md={12}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h5" component="div">
                                Resources for Ideal Career Paths
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2} >
                                Discover resources for your ideal career paths.
                                <IconButton 
                                    aria-label="ideal_resources"
                                    color="primary"
                                    ml={2}
                                    onClick={() => fetchIdealResources()}
                                >
                                    <BsRobot size={20} />
                                </IconButton>   
                            </Typography>
                            {idealResources ? (
                                <Typography variant="body2" color="text.primary" sx={{ whiteSpace: 'pre-wrap' }}>
                                    {idealResources}
                                </Typography>
                            ) : loading2 ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            ) : null}
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Container>
    );
};

export default InterviewPrep;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import './ProfileForm.css';
import { API_URL } from './config';
import {
    TextField, Button, FormControl,
    InputLabel, Select, MenuItem,
    Typography, Paper, Container,
    CssBaseline, Box, FormHelperText
} from '@mui/material';

const ProfileForm = () => {
    const { register, control, handleSubmit, formState: { errors } } = useForm();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [fileName, setFileName] = useState('');
    const [resume, setResume] = useState(null);
    const [otherSkills, setOtherSkills] = useState('');
    const [otherInterests, setOtherInterests] = useState('');
    const [showOtherSkillsField, setShowOtherSkillsField] = useState(false);
    const [showOtherInterestsField, setShowOtherInterestsField] = useState(false);

    const handleResumeUpload = (e) => {
        setResume(e.target.files[0]);
        setFileName(e.target.files[0]?.name || '');
      };

    const onSubmit = data => {
        const formData = new FormData();
        formData.append('work_experience', data.work_experience);
        formData.append('education', data.education);
        formData.append('certifications', data.certifications);
        formData.append('skills', data.skills.concat(otherSkills).join(', '));
        formData.append('interests', data.interests.concat(otherInterests).join(', '));
        formData.append('hobbies', data.hobbies);
        formData.append('languages', data.languages);
        formData.append('work_geo', data.work_geo);
        if (resume) {
            formData.append('resume', resume);
        }

        fetch(`${API_URL}/api/profile/`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
            },
            body: formData,
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(err => { throw err; });
            }
            return response.json();
        })
        .then(data => {
            navigate("/app/chat");
        })
        .catch(error => {
            console.error("An error occurred while creating the profile.", error);
        });
    };

    return (
        <Container component="main" maxWidth="sm" className="profile-form-container">
            <CssBaseline />
            <Paper elevation={3} className="profile-form">
                <Typography component="h1" variant="h5" color="secondary" sx={{ mb: 2 }}>
                    <strong>Complete Your Profile</strong>
                </Typography>                

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography variant="subtitle1" color="primary" sx={{ mt: 2 }}>
                            Upload Resume in PDF: 
                    </Typography>
                    <input
                        type="file"
                        accept=".pdf"
                        id="resume"
                        {...register("resume")}
                        onChange={handleResumeUpload}
                        style={{ display: 'none' }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <label htmlFor="resume">
                            <Button variant="outlined" color="secondary" component="span">
                                Choose File
                            </Button>
                        </label>
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="body2">
                                {fileName || 'No file chosen'}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 3 }}>
                        <hr style={{ flex: 1 }} />
                            <Typography variant="body2" sx={{ mx: 2 }}>
                                OR
                            </Typography>
                        <hr style={{ flex: 1 }} />
                    </Box>
                    <Typography variant="subtitle1" color="primary" sx={{ mt: 2 }}>
                        Alternatively, enter your information below:
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        multiline
                        maxRows={4} 
                        fullWidth
                        label="Work Experience"
                        helperText="Describe your work experience. Include company name, role and duration of employment."
                        {...register("work_experience")}
                    />
                    {errors.work_experience && <span>This field is required</span>}

                    <TextField
                        label="Education"
                        helperText="List the educational institutions you attended, degrees earned, and years of attendance. If you do not have any just write 'None'."
                        variant="outlined"
                        margin="normal"
                        multiline
                        maxRows={4}
                        fullWidth
                        {...register("education")}
                    />
                    {errors.education && <span>This field is required</span>}

                    <TextField
                        label="Certifications and Awards"
                        helperText="Mention any certifications and awards you have received. If you do not have any just write 'None'."
                        variant="outlined"
                        margin="normal"
                        multiline
                        maxRows={4}
                        fullWidth
                        sx={{ mb: 3 }}
                        {...register("certifications")}
                    />
                    {errors.certifications && <span>This field is required</span>}

                    <FormControl fullWidth variant="outlined" sx={{ mb: 3, width: 300 }}>
                        <InputLabel htmlFor="skills">Skills</InputLabel>
                        <Controller
                            name="skills"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <Select
                                    label="Skills"
                                    multiple
                                    fullWidth
                                    value={field.value || []}
                                    onChange={event => {
                                        field.onChange(event.target.value)
                                        setShowOtherSkillsField(event.target.value.includes("Others"));
                                    }}                                            
                                >
                                    <MenuItem value="Communication">Communication</MenuItem>
                                    <MenuItem value="Problem Solving">Problem Solving</MenuItem>
                                    <MenuItem value="Critical Thinking">Critical Thinking</MenuItem>
                                    <MenuItem value="Teamwork">Teamwork</MenuItem>
                                    <MenuItem value="Adaptability">Adaptability</MenuItem>
                                    <MenuItem value="Leadership">Leadership</MenuItem>
                                    <MenuItem value="Time Management">Time Management</MenuItem>
                                    <MenuItem value="Attention to Detail">Attention to Detail</MenuItem>
                                    <MenuItem value="Creativity">Creativity</MenuItem>
                                    <MenuItem value="Technical Proficiency">Technical Proficiency</MenuItem>
                                    <MenuItem value="Data Analysis">Data Analysis</MenuItem>
                                    <MenuItem value="Project Management">Project Management</MenuItem>
                                    <MenuItem value="Decision Making">Decision Making</MenuItem>
                                    <MenuItem value="Customer Service">Customer Service</MenuItem>
                                    <MenuItem value="Conflict Resolution">Conflict Resolution</MenuItem>
                                    <MenuItem value="Negotiation">Negotiation</MenuItem>
                                    <MenuItem value="Multilingualism">Multilingualism</MenuItem>
                                    <MenuItem value="Sales & Marketing">Sales & Marketing</MenuItem>
                                    <MenuItem value="Coding & Programming">Coding & Programming</MenuItem>
                                    <MenuItem value="Public Speaking">Public Speaking</MenuItem>
                                    <MenuItem value="Emotional Intelligence">Emotional Intelligence</MenuItem>
                                    <MenuItem value="Networking">Networking</MenuItem>
                                    <MenuItem value="Financial Literacy">Financial Literacy</MenuItem>
                                    <MenuItem value="Research">Research</MenuItem>
                                    <MenuItem value="Writing & Editing">Writing & Editing</MenuItem>
                                    <MenuItem value="Design Thinking">Design Thinking</MenuItem>
                                    <MenuItem value="Risk Management">Risk Management</MenuItem>
                                    <MenuItem value="Teaching & Mentoring">Teaching & Mentoring</MenuItem>
                                    <MenuItem value="Process Improvement">Process Improvement</MenuItem>
                                    <MenuItem value="Entrepreneurship">Entrepreneurship</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </Select>
                            )}
                        />
                        <FormHelperText>Select the skills that resonate most with your abilities from the list.</FormHelperText>
                    </FormControl>
                    {showOtherSkillsField && (
                        <TextField
                            label="Other Skills"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={otherSkills}
                            onChange={(e) => setOtherSkills(e.target.value)}
                        />       
                    )}     
                    <FormControl fullWidth variant="outlined" sx={{ mb: 1, width: 300 }}>
                        <InputLabel htmlFor="interests">Interests</InputLabel>
                        <Controller
                            name="interests"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <Select
                                    label="Interests"
                                    multiple
                                    value={field.value || []}
                                    onChange={event => {
                                        field.onChange(event.target.value)
                                        setShowOtherInterestsField(event.target.value.includes("Others"));
                                    }}
                                >
                                    <MenuItem value="Technology & Innovation">Technology & Innovation</MenuItem>
                                    <MenuItem value="Business Development">Business Development</MenuItem>
                                    <MenuItem value="Healthcare">Healthcare</MenuItem>
                                    <MenuItem value="Education & Learning">Education & Learning</MenuItem>
                                    <MenuItem value="Arts & Culture">Arts & Culture</MenuItem>
                                    <MenuItem value="Sustainability & Environment">Sustainability & Environment</MenuItem>
                                    <MenuItem value="Travel & Exploration">Travel & Exploration</MenuItem>
                                    <MenuItem value="Community Service">Community Service</MenuItem>
                                    <MenuItem value="Fitness & Well-being">Fitness & Well-being</MenuItem>
                                    <MenuItem value="Food & Cooking">Food & Cooking</MenuItem>
                                    <MenuItem value="Reading & Literature">Reading & Literature</MenuItem>
                                    <MenuItem value="Gaming">Gaming</MenuItem>
                                    <MenuItem value="Music & Performing Arts">Music & Performing Arts</MenuItem>
                                    <MenuItem value="Film & Entertainment">Film & Entertainment</MenuItem>
                                    <MenuItem value="Science & Research">Science & Research</MenuItem>
                                    <MenuItem value="Entrepreneurship & Start-ups">Entrepreneurship & Start-ups</MenuItem>
                                    <MenuItem value="Politics & Governance">Politics & Governance</MenuItem>
                                    <MenuItem value="Social Media & Communication">Social Media & Communication</MenuItem>
                                    <MenuItem value="History & Archaeology">History & Archaeology</MenuItem>
                                    <MenuItem value="Languages & Linguistics">Languages & Linguistics</MenuItem>
                                    <MenuItem value="DIY Projects & Crafting">DIY Projects & Crafting</MenuItem>
                                    <MenuItem value="Psychology & Personal Development">Psychology & Personal Development</MenuItem>
                                    <MenuItem value="Real Estate & Property Management">Real Estate & Property Management</MenuItem>
                                    <MenuItem value="Finance & Investment">Finance & Investment</MenuItem>
                                    <MenuItem value="Sports & Recreation">Sports & Recreation</MenuItem>
                                    <MenuItem value="Adventure & Outdoor Activities">Adventure & Outdoor Activities</MenuItem>
                                    <MenuItem value="Fashion & Beauty">Fashion & Beauty</MenuItem>
                                    <MenuItem value="Animal Care & Wildlife Conservation">Animal Care & Wildlife Conservation</MenuItem>
                                    <MenuItem value="Design & Architecture">Design & Architecture</MenuItem>
                                    <MenuItem value="Gardening & Horticulture">Gardening & Horticulture</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </Select>
                            )}
                        />
                        <FormHelperText>Choose the interests that align closely with your passions from the list.</FormHelperText>
                    </FormControl>
                    {showOtherInterestsField && (
                        <TextField
                            label="Other Interests"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={otherInterests}
                            onChange={(e) => setOtherInterests(e.target.value)}
                        />        
                    )}        
                    <TextField
                        label="Hobbies"
                        helperText="List the hobbies you enjoy doing."
                        variant="outlined"
                        margin="normal"
                        multiline
                        maxRows={4}
                        fullWidth
                        {...register("hobbies")}
                    />
                    {errors.hobbies && <span>This field is required</span>}

                    <TextField
                        label="Languages"
                        helperText="List the languages you speak and indicate the proficiency."
                        variant="outlined"
                        margin="normal"
                        multiline
                        maxRows={4}
                        fullWidth
                        {...register("languages")}
                    />
                    {errors.languages && <span>This field is required</span>}

                    <TextField
                        label="Work Locations"
                        helperText="Indicate the locations (regions, countries, states or cities) that you would be interested in working at."
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        multiline
                        maxRows={4}
                        {...register("work_geo")}
                    />
                    {errors.work_geo && <span>This field is required</span>}
                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, color: '#FFFFFF' }}
                    >
                        Submit
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};

export default ProfileForm;

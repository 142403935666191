import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import './Header.css';
import logo from './images/Isotipo_TrueNorthAI.png'
import { Box } from '@mui/system';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            setIsScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <AppBar position="fixed" className={`appbar${isScrolled ? ' scrolled' : ''}`} elevation={0}>
            <Toolbar className="header">
                <div className="header-content">
                    <div className="logo">                        
                        <a href="#hero-section">
                            <Box component="img" src={logo} alt="Company Logo" sx={{ width: '90px', mt:2 }} />
                        </a>
                    </div>
                    <div className="nav">
                        <a href="#product-section">Product</a>
                        <a href="#safety-section">Safety</a>
                    </div>
                    <div className="auth-buttons">
                        <Link to="login" className="login-btn">Log In</Link>
                        <Link to="signup" className="signup-btn">Sign Up</Link>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;

import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import ProductSection from './ProductSection';
import SafetySection from './SafetySection';

const LandingPage = () => {
    return (
        <div>
            {/* Header */}
            <Header />

            {/* Hero Section */}
            <HeroSection />

            {/* Product, Team, and Safety Sections */}
            <ProductSection />
            <SafetySection />
        </div>
    );
};

export default LandingPage;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Paper, Box, Link } from '@mui/material';
import { useUser } from './UserContext';
import { API_URL } from './config'; 

const SignUpComponent = ({ onSwitchToLogin }) => {
    const { register, handleSubmit, getValues, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const { setCurrentUser } = useUser();
    const [errorMessage, setErrorMessage] = useState(null);

    const onSubmit = (data) => {
        fetch(`${API_URL}/api/create_user/`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Username already exists. Please choose another one.");
            }
            return response.json();
        })
        .then(data => {
            localStorage.setItem('token', data.token);
            setCurrentUser({
                username: data.username,
            });
            navigate("/set_profile");
        })
        .catch(error => {
            console.error("There was a problem with the sign up attempt:", error);
            setErrorMessage(error.message);
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f6f6f6',
            }}
        >
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                    <Typography component="h1" variant="h5" color="secondary" mb={1}>
                        <strong>Create your account</strong>
                    </Typography>
                    <Typography variant="body2" align='center' color="text.secondary" component="div" mb={2}>
                        Note that email verification may be required for signup. Your email will only be used to verify your identity for security purposes.
                    </Typography>
                    {errorMessage && <Box color="error.main" textAlign="center" mb={2}>{errorMessage}</Box>}
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <TextField
                            {...register("name", { required: true })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Name"
                            autoFocus
                            error={!!errors.name}
                            helperText={errors.name && "Name is required"}
                            required
                        />
                        <TextField
                            {...register("last_name", { required: true })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Last Name"
                            error={!!errors.name}
                            helperText={errors.name && "Last Name is required"}
                            required
                        />
                        <TextField
                            {...register("email", { required: "Email is required" })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Email"
                            type="email"
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            required
                        />
                        <TextField
                            {...register("password", { required: true })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Password"
                            type="password"
                            error={!!errors.password}
                            helperText={errors.password && "Password is required"}
                            required
                        />
                        <TextField
                            {...register("repeatPassword", {
                                required: "Please confirm your password",
                                validate: value =>
                                    value === getValues("password") || "Passwords don't match."
                            })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Repeat Password"
                            type="password"
                            required
                            error={!!errors.repeatPassword}
                            helperText={errors.repeatPassword && errors.repeatPassword.message}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Box textAlign="center">
                            <Link                                                        
                                variant="body2"
                                sx={{ cursor: 'pointer' }} 
                                onClick={() => navigate("/login")}
                            >
                                Already have an account? Log in
                            </Link>
                        </Box>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
};

export default SignUpComponent;

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useUser } from './UserContext';
import { API_URL } from './config'; 
import { Button, Container, TextField, Typography, Link, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const FormStyled = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const AlertStyled = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const CSRFToken = () => {
    const [csrftoken, setCSRFtoken] = useState('');

    useEffect(() => {
        setCSRFtoken(getCookie('csrftoken'));
    }, []);

    return (
        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
    );
};

const LoginPage = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { setCurrentUser } = useUser();
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = (data) => {
      const csrftoken = getCookie('csrftoken');

      fetch(`${API_URL}/api/login/`, {
          method: "POST",
          credentials: 'include',
          headers: {
              "Content-Type": "application/json",
              'X-CSRFToken': csrftoken,
          },
          body: JSON.stringify(data)
      })
      .then(response => {
          if (!response.ok) {
            return response.json().then(data => {
                // Here you can also use a more specific error message from the server if it provides one
                throw new Error(data.message || "Network response was not ok");
            });
          }
          return response.json();
      })
      .then(data => {
            if (data.token) {
                // Store the token in local storage
                localStorage.setItem('token', data.token);

                // Set the current user
                setCurrentUser({
                    username: data.username,
                });

                // Navigate to the chat page
                navigate("/app/chat");
            } else {
                console.error("Token not received");
            }
      })
      .catch(error => {
          console.error("There was a problem with the login attempt:", error);
          setErrorMessage(error.message);
      });
  };

  const handleSwitchToSignup = () => {
    navigate("/signup");
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password'); // Navigate to the forgot password page or open a modal
  };

  return (
    <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f6f6f6',
            }}
        >
        <Container component="main" maxWidth="xs">
            <PaperStyled elevation={3}>
                <Typography component="h1" variant="h5">
                <strong>Welcome back</strong>
                </Typography>
                {errorMessage && (
                    <AlertStyled severity="error">{errorMessage}</AlertStyled>
                )}
                <FormStyled noValidate onSubmit={handleSubmit(onSubmit)}>
                    <CSRFToken />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="username"
                        label="Email address"
                        name="username"
                        type="email"
                        autoComplete="username"
                        autoFocus
                        {...register('username', { required: true })}
                        error={Boolean(errors.username)}
                        helperText={errors.username && 'Email address is required'}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        {...register('password', { required: true })}
                        error={Boolean(errors.password)}
                        helperText={errors.password && 'Password is required'}
                    />
                    <Box textAlign="center">
                        <Link variant="body2" onClick={handleForgotPassword} sx={{ cursor: 'pointer' }}>
                        Forgot password?
                        </Link>
                    </Box>
                    <SubmitButton type="submit" fullWidth variant="contained" color="primary">
                        Log in
                    </SubmitButton>
                    <Box textAlign="center">
                        <Link variant="body2" onClick={handleSwitchToSignup} sx={{ cursor: 'pointer' }}>
                        Don't have an account? Sign Up
                        </Link>
                    </Box>
                </FormStyled>
            </PaperStyled>
        </Container>
    </Box>
  );
};

export default LoginPage;

import React, { createContext, useState, useContext, useEffect } from "react";
import { API_URL } from './config'; 

const UserContext = createContext();

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        // Fetch the token from local storage
        const token = localStorage.getItem('token');

        // If token exists, try to fetch the user data
          if (token) {
            fetch(`${API_URL}/api/retrieve-user-info/`, {
                method: "GET",
                headers: {
                    "Authorization": `Token ${token}`
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server responded with an error');
                }
                return response.json();
            })
            .then(data => {
                setCurrentUser({
                    username: data.username,
                }); // Assuming the response has a user property
            })
            .catch(error => {
                localStorage.removeItem('token'); // In case the token is invalid
                setCurrentUser(null);
                window.location.href = '/';
            });
          }
    }, []);

    return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
        {children}
    </UserContext.Provider>
    );
};

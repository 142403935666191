// ResumeBuilder.js
import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./ResumeBuilder.css";
import { API_URL } from "./config";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import DescriptionIcon from '@mui/icons-material/Description';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';

const CoverLetterBuilder = () => {
  const [jobDescription, setJobDescription] = useState("");
  const [enhancedResume, setEnhancedResume] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token');
  const chatContainerRef = useRef(null);

  const handleSubmit = async () => {
    setIsLoading(true);
    setEnhancedResume('');
    const formData = new FormData();
    formData.append("job_description", jobDescription);

    try {
      const response = await fetch(`${API_URL}/api/cover-letter-builder/`, {
        method: "POST",
        headers: {
            'Authorization': `Token ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setEnhancedResume(data.enhanced_resume);
        // Simulate typing effect
        /*const fullText = data.enhanced_resume;
        let index = 0;
        const typingEffect = () => {
            setEnhancedResume(prevEnhancedResume => prevEnhancedResume + fullText[index]);
            index++;
            if (index <= fullText.length) {
                setTimeout(typingEffect, 5); 
            }
        };
        typingEffect();*/
      } else {
        console.error("Failed to enhance resume. HTTP Status:", response.status);
      }

    } catch (error) {
      console.error("Error enhancing resume:", error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const setChatContainerHeight = () => {
      const viewportHeight = window.innerHeight;
      const navbar = document.querySelector('.navbar'); // adjust the selector for your navbar
      const navbarHeight = navbar ? navbar.offsetHeight : 0; // fallback to 60px if navbar is not found
      const appbar = document.querySelector('.mobile-app-bar'); // adjust the selector for your appbar
      const appbarHeight = appbar ? appbar.offsetHeight : 0; // fallback to 0px if appbar is not found
    
      // Calculate the height chat-container should have
      const chatContainerHeight = viewportHeight - navbarHeight - appbarHeight;
      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = `${chatContainerHeight}px`;
      }
    };
    setChatContainerHeight();
    window.addEventListener('resize', setChatContainerHeight);
    return () => {
      window.removeEventListener('resize', setChatContainerHeight);
    };
  }, []);

  return (
    <Container className="main-container" 
        sx={{ 
            py: 0, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '8px'
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#F0F0F0',
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#ccc',
                borderRadius: '10px',
                '&:hover': {
                    backgroundColor: '#bbb'
                }
            } 
        }}
        ref={chatContainerRef}
    >
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center', borderRadius: 3, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                Tailored Cover Letter Builder
            </Typography>
            <Typography variant="subtitle2" gutterBottom color="text.secondary">
                Craft a personalized Cover Letter for a specific job in under 30 seconds! Ensure you've uploaded your latest resume on the Profile page and updated any necessary details for a precisely tailored cover letter.
            </Typography>
            <Box sx={{ my: 4 }}>
                <Divider />
            </Box>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <DescriptionIcon sx={{ fontSize: 40, mb: 2, mr: 1 }} color="primary" />
                        <Typography variant="h6" gutterBottom>
                            Enter Job Description
                        </Typography>
                    </Grid>
                    <Typography variant="body2" align="center" color="text.secondary">
                        Paste the full job description here to tailor your cover letter effectively.
                    </Typography>
                    <TextField
                        className="textarea-scrollbar"
                        multiline
                        maxRows={10}
                        variant="outlined"
                        fullWidth
                        placeholder="Paste the job description here"
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                        sx={{ mt: 2, borderRadius: '5px' }}
                    />
                </Grid>
            </Grid>
            
            <Alert
                severity="info"
                icon={<InfoIcon fontSize="inherit" />}
                sx={{ mt: 2, display: 'flex', alignItems: 'center' }}
            >
                <strong>Heads Up!</strong> Our cutting-edge AI builder may incorporate samples for illustration. Please ensure the final content genuinely represents your skills and experiences before submission to employers.
            </Alert>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                    mt: 2,
                    p: 1,
                    pl: 4,
                    pr: 4,
                    borderRadius: '20px',
                    fontSize: '1.1em',
                    letterSpacing: '1px',
                    textTransform: 'none'
                }}
            >
                Create Cover Letter
            </Button>
            {isLoading && (
                <Box sx={{ textAlign: 'center', mt: 5 }}>
                    <Typography variant="body1">
                        Our AI model is meticulously analyzing your information...
                    </Typography>
                    <CircularProgress sx={{ my: 3 }} />
                    <Typography variant="body1">
                        Kindly give it a few moments.
                    </Typography>
                </Box>
            )}
            {enhancedResume && (
                <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Personalized Cover Letter:
                    </Typography>
                    <TextField
                        className="textarea-scrollbar"
                        multiline
                        maxRows={20}
                        variant="outlined"
                        fullWidth
                        value={enhancedResume}
                        sx={{ borderRadius: 5 }}
                    />
                </Box>
            )}
        </Paper>
    </Container>
  );
};

export default CoverLetterBuilder;

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import ChatView from './ChatView';
import SideMenu from './SideMenu';
import ProfileForm from './ProfileForm';
import ProfileComponent from './ProfileComponent';
import SignUpComponent from './SignUpComponent';
import LandingPage from './LandingPage';
import MobileTabBar from './MobileTabBar';
import Mentors from './Mentors';
import MentorChat from './MentorChat';
import ResumeBuilder from './ResumeBuilder';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import PasswordResetSuccess from './PasswordResetSuccess';
import EmailVerificationPage from './EmailVerificationPage';
import CoverLetterBuilder from './CoverLetterBuilder';
import UserProfile from './UserProfile';
import InterviewPrep from './InterviewPrep';
import InterviewChat from './InterviewChat';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
          main: 'rgb(44, 133, 109)', // Primary color
        },
        secondary: {
          main: '#2C3E50', // Secondary color for side menu
        },
        error: {
          main: '#B85147', // Error color
        },
        background: {
          default: '#F5F5F5' // Soft white background color
        } ,
        white: {
          main: '#FFFFFF' // Soft white background color
        } 
      },
      typography: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
        <Router>
            <Routes>
                <Route path="/" exact element={<LandingPage />} />
                <Route path="/login" exact element={<LoginPage />} /> 
                <Route path="/signup" exact element={<SignUpComponent />} /> 
                <Route path="/set_profile" exact element={<ProfileForm />} /> 
                <Route path="/forgot-password" exact element={<ForgotPassword />} />
                <Route path="/accounts/reset/:uid/:token" exact element={<ResetPassword />} />  
                <Route path="/password-reset-success" exact element={<PasswordResetSuccess />} />  
                <Route path="/email-verification" exact element={<EmailVerificationPage />} />           
                <Route path="/app/*" element={
                  <div>
                    <MobileTabBar />
                    <div style={{ display: 'flex' }}>
                        <SideMenu />
                        <div style={{ flex: 1, padding: '0px' }}>
                            <Routes>
                                <Route path="/chat" element={<ChatView />} />
                                <Route path="/career-track" element={<ProfileComponent />} />
                                <Route path="/profile" element={<UserProfile />} />
                                <Route path="/mentors" element={<Mentors />} />
                                <Route path="/mentor-chat" element={<MentorChat />} />
                                <Route path="/cover-letter" element={<CoverLetterBuilder />} />  
                                <Route path="/resume" element={<ResumeBuilder />} />     
                                <Route path="/interview-prep" element={<InterviewPrep />} />  
                                <Route path="/interview-chat" element={<InterviewChat />} />                          
                            </Routes>
                        </div>
                    </div>
                  </div>
                } />
            </Routes>
        </Router>
    </ThemeProvider>
  );
}

export default App;

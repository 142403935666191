import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { API_URL } from './config'; 
import './MobileTabBar.css';
import Isotipo from './images/Isotipo_TrueNorthAI.png';
import Logotipo from './images/Logotipo_TrueNorthAI.png';
import FeedbackForm from './FeedbackForm';
import { useUser } from './UserContext';

const FEATURES = ['AI Career Coach', 'Profile', 'AI Mentors', 'Cover Letter Builder', 'Resume Builder', 'Career Track', 'Interview Prep'];

const MobileTabBar = () => {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const { currentUser } = useUser();
    const token = localStorage.getItem('token');
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  
    const menuItems = [
        { path: '/app/chat', label: 'AI Career Coach', icon: 'fa-solid fa-comment' },
        { path: '/app/profile', label: 'Profile', icon: 'fa-solid fa-address-card' },
        { path: '/app/mentors', label: 'AI Mentors', icon: 'fa-solid fa-rocket' },
        { path: '/app/cover-letter', label: 'Cover Letter Builder', icon: 'fa-solid fa-file' },
        { path: '/app/resume', label: 'Resume Builder', icon: 'fa-solid fa-file' },
        { path: '/app/career-track', label: 'Career Track', icon: 'fa-solid fa-chart-line' },
        { path: '/app/interview-prep', label: 'Interview Prep', icon: 'fa-solid fa-dumbbell' },
    ];

    const handleLogout = () => {
        // Call the backend API to log out
        fetch(`${API_URL}/api/logout/`, { 
            method: 'POST', 
            credentials: 'include', // This is important to include the session cookie
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`,
            },
        })
        .then((response) => {
            if (response.ok) {
                localStorage.removeItem('token');
                // Redirect user to the login page after logout
                navigate('/');
            } else {
                console.error('Failed to log out');
            }
        })
        .catch((error) => console.error('Error:', error));
    };

    const handleSubmitFeedback = async (feedbackData) => {
        setShowFeedbackForm(false);
        try {
            const response = await fetch(`${API_URL}/api/feedback/`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username: currentUser.username, ...feedbackData })
            });
        
            if (response.ok) {
            // If the feedback was successfully submitted, set a flag in the local storage
            localStorage.setItem(`feedback_given_${currentUser.username}`, 'true');
            } else {
            console.error(`Error: ${response.statusText}`);
            }
        } catch (error) {
            console.log('Error submitting feedback', error);
        }
    };

    const handleCloseFeedback = () => {
        setShowFeedbackForm(false);
    };

    return (
        <AppBar position="static" className="mobile-app-bar">
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <IconButton edge="start" color="inherit" onClick={() => setOpenDrawer(true)} >
                    <MenuIcon />
                </IconButton>
                <img alt="logo" src={Logotipo} style={{ width:'160px', height:'auto' }} />
                <div style={{ width: '48px' }} />
            </Toolbar>
            <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)} PaperProps={{ className: 'mobile-drawer-paper' }}>
                <Box display="flex" flexDirection="column" height="100%">
                    <ListItem>
                        <ListItemIcon>
                            <img alt="logo" src={Isotipo} style={{ width:'60px', height:'auto', marginTop:'10px', marginBottom:'10px' }} />
                        </ListItemIcon>
                        <ListItemIcon>
                            <img alt="logotype" src={Logotipo} style={{ width:'140px', height:'auto', marginTop:'10px', marginBottom:'10px' }} />
                        </ListItemIcon>
                    </ListItem>
                    <Divider sx={{ marginBottom: '8px' }} />
                    <List sx={{ flexGrow: 1 }}>
                        {menuItems.map((item) => (
                            <ListItem button key={item.path} onClick={() => {
                                                                navigate(item.path);
                                                                setOpenDrawer(false);
                                                                }}>
                                <ListItemIcon sx={{ color: "white" }}>
                                    <i className={`fas ${item.icon}`} style={{color:"white"}}/>
                                </ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItem>
                        ))}
                    </List>
                    <Divider sx={{ marginBottom: '10px' }} />
                    <ListItem button onClick={() => setShowFeedbackForm(true)} >
                        <ListItemIcon>
                            <i className="fa-solid fa-envelope-open" style={{color:"white"}} />
                        </ListItemIcon>
                        <ListItemText primary="Send Feedback" />
                    </ListItem>
                    <ListItem button onClick={() => {
                                        setOpenDrawer(false);
                                        handleLogout();
                                        }} 
                                        sx={{ marginTop: 'auto', marginBottom: '15px' }}>
                        <ListItemIcon>
                            <i className="fas fa-sign-out-alt" style={{color:"white"}} />
                        </ListItemIcon>
                        <ListItemText primary="Log Out" />
                    </ListItem>
                </Box>
                {showFeedbackForm && (
                <FeedbackForm features={FEATURES} onSubmit={handleSubmitFeedback} onClose={handleCloseFeedback} />
                )}
            </Drawer>
        </AppBar>
    );
};

export default MobileTabBar;

import React from "react";
import Box from "@mui/material/Box";
import { styled, keyframes } from "@mui/system";

const typingAnimation = keyframes`
  0%, 20% { transform: translateY(0px); }
  50% { transform: translateY(-5px); }
  100%, 80% { transform: translateY(0px); }
`;

const Dot = styled("div")`
  background-color: #333;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 10px 2px 0px;
  animation: ${typingAnimation} 1.5s infinite;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const TypingIndicator = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Dot />
      <Dot />
      <Dot />
    </Box>
  );
};

export default TypingIndicator;

import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function EmailVerificationPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      padding={2}
    >
      <Typography variant="h4" gutterBottom>
        Email Verified Successfully!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for verifying your email. You can now log in to your account.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => window.location.href = "/login"}
      >
        Go to Login
      </Button>
    </Box>
  );
}

export default EmailVerificationPage;

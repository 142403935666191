import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Paper, Container } from '@mui/material';
import { useForm } from 'react-hook-form';
import { API_URL } from './config'; 
import CSRFToken from './CSRFToken';
//import { getCookie } from './utils';

const ResetPassword = () => {
    const [error, setError] = useState(null);
    const { register, handleSubmit, getValues, formState: { errors } } = useForm();
    const { uid, token } = useParams();
    const navigate = useNavigate();

    /*useEffect(() => {
        // Function to fetch the CSRF token
        const fetchCSRFToken = async () => {
            try {
                // We're not saving the response since the token will be saved as a cookie
                await fetch(`${API_URL}/set_csrf_token/`, { 
                    credentials: 'include', // Include credentials to get the cookie
                });
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        // Fetch the CSRF token
        fetchCSRFToken();
    }, []);*/

    const onSubmit = async (formData) => {
        //const csrftoken = getCookie('csrftoken');

        const response = await fetch(`${API_URL}/api/custom_reset_password/` + uid + '/' + token + '/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            //    'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({ new_password: formData.password }),
        });

        // Check if the password reset was successful
        if (response.ok) {
            // Redirect to a success page
            navigate('/password-reset-success');
        } else {
            // Display an error message
            const errorData = await response.json();
            setError(errorData.error);
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <form noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
                    <CSRFToken />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        {...register("password", { required: true })}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm new password"
                        type="password"
                        id="confirm-password"
                        autoComplete="new-password"
                        {...register("confirmPassword", {
                            required: true,
                            validate: value =>
                                value === getValues("password") || "Passwords don't match."
                        })}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword && errors.confirmPassword.message}
                    />
                    {error && (
                        <Typography component="p" sx={{ color: 'red' }}>
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Reset Password
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};

export default ResetPassword;



import React, { useState, useEffect } from 'react';
import { getCookie } from './utils';

const CSRFToken = () => {
    const [csrftoken, setCSRFtoken] = useState('');

    useEffect(() => {
        const token = getCookie('csrftoken');
        setCSRFtoken(token ? token : "");
    }, []);

    return (
        <input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
    );
};

export default CSRFToken;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
//import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Paper, Box } from '@mui/material';
import { API_URL } from './config'; 
import CircularProgress from '@mui/material/CircularProgress';

const SignUpComponent = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    //const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    //const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (data) => {
        setIsLoading(true);

        //fetch(`${API_URL}/accounts/password_reset/`, {
        fetch(`${API_URL}/api/forgot_password/`, {
            method: "POST",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("This email address is not in our records.");
            }
            else {
                setMessage('Password reset email has been sent.');
            }
            //return response.json();
        })
       /* .then(data => {
            navigate("/login");
        }) */
        .catch(error => {
            console.error("There was a problem with the sign up attempt:", error);
            setErrorMessage(error.message);
        });
        setIsLoading(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f6f6f6',
            }}
        >
            <Container component="main" maxWidth="xs">
                <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                    <Typography component="h1" variant="h5" color="secondary" mb={1}>
                        <strong>Change your password</strong>
                    </Typography>                    
                    {errorMessage && <Box color="error.main" textAlign="center" mb={2}>{errorMessage}</Box>}
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <TextField
                            {...register("email", { required: true })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            type="email"
                            label="Email address"
                            error={!!errors.email}
                            helperText={errors.email && "Email address is required"}
                            required
                        />
                      { /*  <TextField
                            {...register("password", { required: true })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Password"
                            type="password"
                            error={!!errors.password}
                            helperText={errors.password && "Password is required"}
                            required
                        />
                        <TextField
                            {...register("repeatPassword", {
                                required: "Please confirm your password",
                                validate: value =>
                                    value === getValues("password") || "Passwords don't match."
                            })}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Repeat Password"
                            type="password"
                            required
                            error={!!errors.repeatPassword}
                            helperText={errors.repeatPassword && errors.repeatPassword.message}
                        /> */ }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2, mb: 2 }}
                        >
                            Reset Password
                        </Button>
                    </form>
                    {isLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    )}
                    <Typography variant="body2" align='center' color="text.secondary" component="div" mb={2}>
                        {message && <div>{message}</div>}
                    </Typography>
                </Paper>
            </Container>
        </Box>
    );
};

export default SignUpComponent;

// ProfileComponent.js
import React, { useEffect, useState, useRef } from 'react';
import { useUser } from './UserContext';
import { API_URL } from './config'; 
import { Box, Card, CardContent, CardHeader, CircularProgress, Container, Grid, Typography, Avatar, IconButton } from '@mui/material';
import PersonalityIcon from '@mui/icons-material/EmojiObjects'; // You can choose icons that you find suitable
import CareerPathIcon from '@mui/icons-material/Explore';
import SkillsIcon from '@mui/icons-material/School';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SubjectIcon from '@mui/icons-material/Subject';
import { BsRobot } from 'react-icons/bs';
import './ProfileComponent.css';

const ProfileComponent = () => {
    const { currentUser } = useUser();
    const [userInfo, setUserInfo] = useState(null);
    const [userBasicInfo, setUserBasicInfo] = useState(null);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        // Fetch user info when the component mounts
        if (currentUser && currentUser.username){
            fetchUserBasicInfo(currentUser.username);
            fetchUserInfo(currentUser.username);
        }
    }, [currentUser]);
    
    useEffect(() => {
        const setChatContainerHeight = () => {
          const viewportHeight = window.innerHeight;
          const navbar = document.querySelector('.navbar'); // adjust the selector for your navbar
          const navbarHeight = navbar ? navbar.offsetHeight : 0; // fallback to 60px if navbar is not found
          const appbar = document.querySelector('.mobile-app-bar'); // adjust the selector for your appbar
          const appbarHeight = appbar ? appbar.offsetHeight : 0; // fallback to 0px if appbar is not found
        
          // Calculate the height chat-container should have
          const chatContainerHeight = viewportHeight - navbarHeight - appbarHeight;
          if (chatContainerRef.current) {
            chatContainerRef.current.style.height = `${chatContainerHeight}px`;
          }
        };
        setChatContainerHeight();
        window.addEventListener('resize', setChatContainerHeight);
        return () => {
          window.removeEventListener('resize', setChatContainerHeight);
        };
      }, []);

    const fetchUserBasicInfo = async (username) => {
        const token = localStorage.getItem('token');
        try {
            // Send the user's message to the backend API for processing
            const response = await fetch(`${API_URL}/api/get_user_basic/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: username }),
            });

            if (response.ok) {
                const data = await response.json();
                setUserBasicInfo(data);
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
            // Handle error case if necessary
        }
    };

    const fetchUserInfo = async (username) => {
        const token = localStorage.getItem('token');
        try {
            // Send the user's message to the backend API for processing
            const response = await fetch(`${API_URL}/api/get_assessment/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: username }),
            });

            if (response.ok) {
                const data = await response.json();
                setUserInfo(data);
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
            // Handle error case if necessary
        }
    };

    const fetchUserAssessment = async (field) => {
        const token = localStorage.getItem('token');
        try {           
            setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                [field]: null,
            }));
            // Send the user's message to the backend API for processing
            const response = await fetch(`${API_URL}/api/assess/`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({ username: currentUser.username, field: field }),
            });

            if (response.ok) {
                const data = await response.json();

                // Simulate typing effect
                const fullText = data.assessment;
                let index = 0;
                const typingEffect = () => {
                    setUserInfo(prevUserInfo => ({
                        ...prevUserInfo,
                        [field]: fullText.slice(0, index),
                    }));
                    index++;
                    if (index <= fullText.length) {
                        setTimeout(typingEffect, 10); 
                    }
                };
                typingEffect();
            } else {
                console.error('Failed to fetch user info');
            }

        } catch (error) {
            console.error('An error occurred while fetching user info:', error);
            setUserInfo(prevUserInfo => prevUserInfo);
        }
    };
    

    return (
        <Container 
            sx={{
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#F0F0F0',
                    borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#ccc',
                    borderRadius: '10px',
                    '&:hover': {
                        backgroundColor: '#bbb'
                    }
                }
            }}
            ref={chatContainerRef}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 4 }}>
                {userBasicInfo && userInfo ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
                        <Avatar sx={{ width: 80, height: 80, mr: 2 }} src={userBasicInfo.profile_picture} alt="Profile Picture" />                
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                            <Typography variant="h4" component="div" sx={{ mt: 1 }}>
                                {userBasicInfo.name} {userBasicInfo.last_name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {userInfo.professional_headline}
                                <IconButton 
                                    aria-label="professional_headline"
                                    color="primary"
                                    onClick={() => fetchUserAssessment("professional_headline")}
                                >
                                    <BsRobot size={20} />
                                </IconButton>
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="h6">Loading...</Typography>
                )}
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader 
                            title={<Typography variant="body2" color="primary">Click on this icon at each section to generate assessment with AI.</Typography>}
                            avatar={<BsRobot color="rgb(44, 133, 109)" />}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader 
                            title="Summary"
                            avatar={<SubjectIcon />}
                            action={
                                <IconButton 
                                    aria-label="summary"
                                    color="primary"
                                    onClick={() => fetchUserAssessment("summary")}
                                >
                                    <BsRobot size={20} />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {userInfo && userInfo.summary ? (
                                <Typography className="cool-scrollbar" variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(1.5em * 12)', overflow: 'auto' }}>
                                    {userInfo.summary}
                                </Typography>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader 
                            title="Ideal Career Paths"
                            avatar={<CareerPathIcon />}
                            action={
                                <IconButton 
                                    aria-label="ideal_paths"
                                    color="primary"
                                    onClick={() => fetchUserAssessment("ideal_paths")}
                                >
                                    <BsRobot size={20} />
                                </IconButton>
                            } 
                        />
                        <CardContent>
                            {userInfo && userInfo.ideal_paths ? (
                                <Typography className="cool-scrollbar" variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(1.5em * 20)', overflow: 'auto' }}>
                                    {userInfo.ideal_paths}
                                </Typography>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader
                            title="Skills to Develop"
                            avatar={<SkillsIcon />} 
                            action={
                                <IconButton 
                                    aria-label="gaps"
                                    color="primary"
                                    onClick={() => fetchUserAssessment("gaps")}
                                >
                                    <BsRobot size={20} />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {userInfo && userInfo.gaps ? (
                                <Typography className="cool-scrollbar" variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(1.5em * 20)', overflow: 'auto' }}>
                                    {userInfo.gaps}
                                </Typography>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Personality Assessment Card */}
                <Grid item xs={12} sm={6} md={6}>
                    <Card>
                        <CardHeader
                            title="Personality Assessment"
                            avatar={<PersonalityIcon />}
                            action={
                                <IconButton 
                                    aria-label="personality"
                                    color="primary"
                                    onClick={() => fetchUserAssessment("personality")}
                                >
                                    <BsRobot size={20} />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {userInfo && userInfo.personality ? (
                                <Typography className="cool-scrollbar" variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(1.5em * 20)', overflow: 'auto' }}>
                                    {userInfo.personality}
                                </Typography>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                {/* Big Five Model Card */}
                <Grid item xs={12} sm={6} md={6} mb={2}>
                    <Card>
                        <CardHeader
                            title="Big Five Model"
                            avatar={<AssessmentIcon />}
                            action={
                                <IconButton 
                                    aria-label="big_five"
                                    color="primary"
                                    onClick={() => fetchUserAssessment("big_five")}
                                >
                                    <BsRobot size={20} />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            {userInfo && userInfo.big_five ? (
                                <Typography className="cool-scrollbar" variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap', maxHeight: 'calc(1.5em * 20)', overflow: 'auto' }}>
                                    {userInfo.big_five}
                                </Typography>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Typography variant="body2">Analyzing information with AI...</Typography>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
    
};

export default ProfileComponent;

import React, { useRef, useEffect } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import './Mentors.css'; 

const StyledList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100vh',
  overflowY: 'auto',
  '& li': {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
          width: '50%',
      },
      [theme.breakpoints.up('md')]: {
          width: '33.33%',
      },
  }
}));

const Mentors = () => {
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();

  const contacts = [
    {
        name: 'Steve Wozniak',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/wozniak.png',
        jobDescription: 'Software Engineer',
        background: 'Steve is an electronics engineer, programmer, philanthropist, and technology entrepreneur. He is best known as the co-founder of Apple Inc.'
    },
    {
        name: 'Paul Rand',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/rand.png',
        jobDescription: 'Graphic Designer',
        background: 'Paul is a creative graphic designer, widely known for his corporate logo designs, including the logos for IBM, UPS, Enron, Morningstar, Inc., Westinghouse, ABC, and NeXT.'
    },
    {
        name: 'Michael Scott',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/scott.png',
        jobDescription: 'Partner at BCG',
        background: 'Michael has over 15 years of experience in management consulting and is currently a Partner at Boston Consulting Group. He specializes in corporate strategy and operations.'
    },
    {
        name: 'Sara O\'Neil',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/oneil.png',
        jobDescription: 'High School Teacher',
        background: 'Sara is a high school teacher with 8 years of experience. She teaches Mathematics and Physics, and has been awarded for her innovative teaching methods.'
    },
    {
        name: 'Chris Martin',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/martin.png',
        jobDescription: 'Musician',
        background: 'Chris is a professional musician and composer. He is a multi-instrumentalist with a focus on piano and vocals, and has performed worldwide.'
    },
    {
        name: 'Leonardo Da Vinci',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/davinci.png',
        jobDescription: 'Painter',
        background: 'Leonardo is an established painter whose work has been featured in numerous exhibitions. His style blends abstract expressionism with a modern twist.'
    },
    {
        name: 'James Bond',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/bond.png',
        jobDescription: 'Investment Banker',
        background: 'James is an investment banker with over 10 years of experience. He specializes in mergers and acquisitions, and has been involved in some of the biggest deals in the industry.'
    },
    {
        name: 'Jessica Pearson',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/pearson.png',
        jobDescription: 'Private Equity Analyst',
        background: 'Jessica is a Private Equity Analyst with a keen eye for lucrative investments. She excels at financial modeling and portfolio management.'
    },
    {
        name: 'Robert Herjavec',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/herjavec.png',
        jobDescription: 'Venture Capital Investor',
        background: 'Robert is a venture capitalist and entrepreneur, known for his sharp investment strategies. He has a strong portfolio of successful startups across various industries.'
    },
    {
        name: 'Elon Musk',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/musk.png',
        jobDescription: 'Entrepreneur',
        background: 'Elon is a world-renowned entrepreneur known for co-founding several high-profile technology companies, including SpaceX, Tesla, Neuralink, and others.'
    },
    {
        name: 'Sundar Pichai',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/pichai.png',
        jobDescription: 'Product Manager at Google',
        background: 'Sundar is a Product Manager at Google. He is responsible for overseeing the development of a range of Google’s products and ensuring their success in the market.'
    },
    {
        name: 'Leonardo DiCaprio',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/dicaprio.png',
        jobDescription: 'Actor',
        background: 'Leonardo DiCaprio is an award-winning actor known for his performances in films like Titanic, Inception, The Wolf of Wall Street, and The Revenant. He is also a committed environmental activist.'
    },
    {
        name: 'Harvey Specter',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/specter.png',
        jobDescription: 'Lawyer',
        background: 'Harvey Specter is a renowned lawyer specializing in corporate law. He is known for his sharp wit, excellent negotiation skills, and charismatic personality.'
    },
    {
        name: 'Lionel Messi',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/messi.png',
        jobDescription: 'Footballer',
        background: 'Lionel Messi, commonly known as Leo Messi, is an Argentine professional soccer player who plays as a forward for Paris Saint-Germain and the Argentina national team. Widely regarded as one of the greatest football players of all time, Messi has numerous records and accolades to his name.'
    },
    {
        name: 'Roger Federer',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/federer.png',
        jobDescription: 'Tennis Player',
        background: 'Roger Federer is a Swiss professional tennis player. He has won 20 Grand Slam men\'s singles titles, and held the world No. 1 spot in the ATP rankings a total of 310 weeks. Federer is considered one of the greatest tennis players of all time.'
    },
    {
        name: 'LeBron James',
        avatar: 'https://projectx-storage.s3.us-east-2.amazonaws.com/profile_pics/james.png',
        jobDescription: 'Basketball Player',
        background: 'LeBron James is an American professional basketball player for the Los Angeles Lakers of the National Basketball Association (NBA). Widely regarded as one of the greatest basketball players in NBA history, LeBron has been an NBA champion four times and has received numerous accolades and honors.'
    },
  ];

  useEffect(() => {
    const setChatContainerHeight = () => {
      const viewportHeight = window.innerHeight;
      const navbar = document.querySelector('.navbar'); 
      const navbarHeight = navbar ? navbar.offsetHeight : 0; 
      const appbar = document.querySelector('.mobile-app-bar'); 
      const appbarHeight = appbar ? appbar.offsetHeight : 0; 

      const chatContainerHeight = viewportHeight - navbarHeight - appbarHeight;

      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = `${chatContainerHeight}px`;
      }
    };

    setChatContainerHeight();

    window.addEventListener('resize', setChatContainerHeight);

    return () => {
      window.removeEventListener('resize', setChatContainerHeight);
    };
  }, []);

  const onSelectContact = (contact) => {
    navigate("/app/mentor-chat", { state: { contact } });
  };

  return (
      <StyledList className="custom-scrollbar" ref={chatContainerRef}>
          {contacts.map((contact, index) => (
              <ListItem
                  button
                  key={index}
                  onClick={() => onSelectContact(contact)}
              >
                  <ListItemAvatar>
                      <Avatar alt={contact.name} src={contact.avatar} sx={{ width: 56, height: 56, mr: 2 }} />
                  </ListItemAvatar>
                  <ListItemText
                      primary={contact.name}
                      secondary={
                        <>
                            <Typography component="span" variant="body2" style={{ fontWeight: 'bold' }}>
                                {contact.jobDescription}
                            </Typography>
                            <br />
                            <Typography component="span" variant="body2" color="text.secondary">
                                {contact.background}
                            </Typography>
                        </>
                      }
                  />
              </ListItem>
          ))}
      </StyledList>
  );
};

export default Mentors;

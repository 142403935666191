import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; 
import './SafetySection.css';

const SafetySection = () => {
    return (
        <Box id="safety-section" className="safety-section" component={Paper} elevation={3} py={5} px={2}>
            <Container>
                <Grid container className="safety-content" alignItems="center">
                    <Grid item xs={12} md={6} className="safety-text">
                        <Typography variant="h4">Data Privacy, Safety, and Peace of Mind</Typography>
                        <Typography variant="body1">
                            At True North AI, we understand that your personal information is valuable and sensitive. 
                            Our commitment to your data privacy and security is unwavering:
                        </Typography>
                        <br />
                        <Typography variant="body1"><strong style={{ color:'#7696CE', fontStyle:'italic' }}>Encryption:</strong> Your data is encrypted both in transit and at rest, employing state-of-the-art cryptographic methods.</Typography>
                        <Typography variant="body1"><strong style={{ color:'#7696CE', fontStyle:'italic' }}>Compliance:</strong> We rigorously comply with international data protection laws, including GDPR, to safeguard your information.</Typography>
                        <Typography variant="body1"><strong style={{ color:'#7696CE', fontStyle:'italic' }}>Minimal Data Collection:</strong> We only ask for the information that’s necessary to provide you with a stellar service. No more, no less.</Typography>
                        <Typography variant="body1"><strong style={{ color:'#7696CE', fontStyle:'italic' }}>Control and Consent:</strong> You have control over your data. We are transparent in how we use it, and we never share it without your explicit consent.</Typography>
                        <Typography variant="body1"><strong style={{ color:'#7696CE', fontStyle:'italic' }}>Continuous Monitoring:</strong> Our systems are continually monitored for vulnerabilities and threats to ensure proactive protection.</Typography>
                        <Typography variant="body1"><strong style={{ color:'#7696CE', fontStyle:'italic' }}>Dedicated Support:</strong> Have questions or concerns about your data? Our support team is here to help you every step of the way.</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} className="safety-image" mt={3}>
                        <div className="outter" style={{
                            position: 'relative',
                            width: '100%',
                            height: '400px', // change this to your requirement
                            overflow: 'hidden',
                        }}>
                            <img 
                                src="https://dlabs.ai/wp-content/uploads/2020/02/safety.jpg"
                                alt="Safety" 
                                style={{
                                    width: '100%', 
                                    height: '100%', 
                                    position: 'absolute', 
                                    objectFit: 'cover', 
                                    zIndex: 1 
                                }} 
                            />
                            <div className="transparency" style={{
                                content: '""', 
                                position: 'absolute', 
                                top: 0, 
                                right: '50%', 
                                width: '50%', 
                                height: '100%', 
                                background: 'linear-gradient(to left, transparent, #1d2336)', 
                                zIndex: 2 
                            }}></div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default SafetySection;